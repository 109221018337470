// @import '~aos/src/sass/aos';
// @import "~aos/dist/aos";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/bootstrap/scss/functions";
@import "./mixins/remcalc";
@import "./mixins/yiq";
@import "./mixins/background-opacity";

@import "./themes/databowl";

@import "../../node_modules/bootstrap/scss/variables";

@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/jumbotron";
// @import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
// @import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

@import "./helpers/helpers";

@import "./components/intercom";

@import "./components/nav";
// @import "./components/hero";
// @import "./components/home_success";
// @import "./components/pricing";
@import "./components/journal_content";
@import "./components/syntax_highlighter";

@import "./components/success-card";

// @import "./components/form";

// @import "./components/carousel";
// @import "./components/multistep";
// @import "./components/review_carousel";
// @import "./components/chatbot";
// @import "./components/stickycontainer";

@import "./hacks/IE11";
// @import "./hacks/databowl";

@import "./custom/fontawesome";

@import "./custom/style";

@import "./components/chat";

.main-timeline {
  .timeline {
    position: relative;
    &:before,
    &:after {
      content: "";
      display: block;
      clear: both;
    }

    &:before {
      @include media-breakpoint-up(md) {
        width: 0.5rem;
        height: 130%;
        background: $light;
        margin: 0 auto;
        position: absolute;
        top: -60%;
        left: 0;
        right: 0;
      }
    }

    &:first-child:before {
      height: 50%;
      border-radius: 0.5rem 0.5rem 0 0;
      top: 0;
    }
    &:last-child:before {
      height: 150%;
      border-radius: 0 0 0.5rem 0.5rem;
    }

    .timeline-icon {
      @extend .bg-light;
      display: block;
      width: 5rem;
      height: 5rem;
      line-height: 4rem;
      font-size: 2.25rem;
      border-radius: 50%;
      border: 0.5rem solid $light;
      margin: auto;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      z-index: 1;

      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        //   bottom: 0;
        right: 0;
      }

      &:before {
        @include media-breakpoint-up(md) {
          content: "";
          width: 0;
          height: 0;
          border-right: 15px solid $primary;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          margin: auto 0;
          position: absolute;
          top: 0;
          left: -33px;
          bottom: 0;
        }
      }
    }

    .timeline-content {
      @include media-breakpoint-up(md) {
        width: 50%;
        padding-right: 5rem;
      }
    }

    .timeline-content-title {
      @include media-breakpoint-up(md) {
        margin: 0;
        width: 50%;
        padding-left: 20px;
        position: absolute;
        right: 0;
      }
      span {
        @extend .bg-light;
        padding: 12px 25px 12px 25px;
        display: block;
        margin: -1rem auto 1rem auto;
        text-align: center;
        @include media-breakpoint-up(md) {
          margin: unset;
          text-align: left;
          display: inline-block;
          border-radius: 0 50px 50px 0;
        }
      }
    }

    &.timeline-right {
      .timeline-icon:before {
        @include media-breakpoint-up(md) {
          border-right: none;
          border-left: 15px solid $primary;
          left: auto;
          right: -33px;
        }
      }
      .timeline-content {
        @include media-breakpoint-up(md) {
          float: right;
          padding: 0 0 0 5rem;
        }
      }
      .timeline-content-title {
        @include media-breakpoint-up(md) {
          padding: 0 20px 0 0;
          text-align: right;
          right: auto;
          left: 0;
        }
      }

      .timeline-content-title span {
        @include media-breakpoint-up(md) {
          border-radius: 50px 0 0 50px;
        }
      }
    }

    &:last-child {
      &:before {
        height: 60%;
      }
    }
  }
}
